*,
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Helvetica, arial, sans-serif;
}

.app {
  background-color: black;
  width: 100vw;
  height: 100vh;
  &__lotti {
    width: 100%;
    height: 100%;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}